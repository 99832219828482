<template>
  <div>
    <h2>応募一覧</h2>
    <div class="">
      求人情報への応募状況を反映しています。
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        応募した案件に関しては、事務局より連絡がございます。
      </div>
    </b-alert>
    <b-row class="pricing-card card_">
      <h3
        v-if="dataList.length === 0"
      >
        応募件数は０件です。
        <router-link
          to="jobslist"
        >
          こちらから求人情報を検索できます。
        </router-link>
      </h3>
      <b-card
        v-for="(item, index) in dataList"
        v-else
        :key="index"
        title=""
        class="popular card_"
      >
        <h3 class="">
          <b-row>
            <b-col xl="8">
              <feather-icon
                icon="UsersIcon"
                size="24"
                class="text-primary"
              />
              <span class="titleText">
                {{ item.職種名 }}
              </span>
            </b-col>
            <b-col
              xl="4"
              class="text-right categoryLabel"
            >
              <span class="text-primary"><b>{{ item.職種カテゴリー }}</b></span>
            </b-col>
          </b-row>
        </h3>
        <span class="day_">
          お申込み日時：{{ item.insertTime | formatDate }}
        </span>
        <b-badge
          variant="light-primary"
          class=""
        >
          {{ item['仕事内容（勤務時間・曜日）'] }}
        </b-badge>
        <b-badge
          variant="light-success"
          class=""
        >
          {{ item.勤務地 }}
        </b-badge>
        <div class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
            @click="detail(item)"
          >
            詳細
          </b-button>
        </div>
      </b-card>
    </b-row>
    <b-modal
      v-model="isShowModal"
      title="詳細情報"
      ok-variant="outline-secondary"
      ok-title="閉じる"
      ok-only
      scrollable
      size="lg"
    >
      <b-card-text class="mt-5">
        <span
          class="textM outline_M"
        >
          {{ dataDic.勤務地 }}
        </span>
        <span
          class="textA outline_M ml-1 text-primary"
        >
          <b>{{ dataDic.職種カテゴリー }}</b>
        </span>
      </b-card-text>
      <b-card-text>
        <div class="title_">
          <b>{{ dataDic.職種名 }}</b>
        </div>
        <div class="">
          <span class="tag_ outline_M">
            {{ dataDic.タグ }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_1 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_2 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_3 }}
          </span>
          <span class="tag_ outline_M">
            {{ dataDic.タグ_4 }}
          </span>
        </div>
        <div class="mt-1">
          <span
            v-if="dataDic['給与（下限）'] !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ dataDic.給与種別 }}</b></small> <b>{{ dataDic['給与（下限）'] | localeNumberShow }}円</b>
          </span>
          <span class="title_ text-primary">
            〜
          </span>
          <span
            v-if="dataDic.給与上限 !== ''"
            class="title_ mt-1 text-primary text-right"
          >
            <small><b>{{ dataDic.給与種別 }}</b></small> <b>{{ dataDic.給与上限 | localeNumberShow }}円</b>
          </span>
        </div>
        <div class="textA mt-1">
          {{ dataDic.求人キャッチコピー }}
        </div>
        <hr>
        <div class="textC mt-1">
          【求める人材】{{ dataDic['仕事内容（求める人材）'] }}
        </div>
        <div class="tag_ mt-1">
          【雇用形態】{{ dataDic['雇用形態'] }}
        </div>
        <div class="textA mt-1">
          【勤務時間・曜日】{{ dataDic['仕事内容（勤務時間・曜日）'] }}
        </div>
        <div class="textA mt-1">
          【お仕事内容】{{ dataDic['仕事内容（仕事内容）'] }}
        </div>
        <div class="textA mt-1">
          【アピールポイント】{{ dataDic['仕事内容（アピールポイント）'] }}
        </div>
        <div class="textA mt-1">
          【休暇・休日】{{ dataDic['仕事内容（休暇・休日）'] }}
        </div>
        <div class="textA mt-1">
          【待遇・福利厚生】{{ dataDic['仕事内容（待遇・福利厚生）'] }}
        </div>
        <div class="textA mt-1">
          【勤務地】{{ dataDic['仕事内容（勤務地）'] }}
        </div>
        <div class="textA mt-1">
          【アクセス】{{ dataDic['仕事内容（アクセス）'] }}
        </div>
        <div class="textZ mt-1">
          【その他】{{ dataDic['仕事内容（その他）'] }}
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BAlert, BButton, BBadge, BRow, BCol, BModal, BCardText,
} from 'bootstrap-vue'
import getDataListOrderByInsertTimeDesc from '@/firestore/data/getListTimeDesc'
import fields from '@/components/jobSeeker/apply/fields.json'
import dateformate from '@/components/js/filter/formatDateMinutes'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BBadge,
    BRow,
    BCol,
    BModal,
    BCardText,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDate(val) {
      return dateformate(val)
    },
    localeNumberShow(val) {
      try {
        return val.toLocaleString()
      } catch {
        // something
        return ''
      }
    },
  },
  props: {
  },
  data() {
    return {
      dataList: [],
      fields,
      dateformate,
      isShowModal: false,
      dataDic: {},
      status: '',
      status2: '',
    }
  },
  async mounted() {
    const data = {
      collection: 'apply',
    }
    const response = await getDataListOrderByInsertTimeDesc(data)
    if (response.status === 'success') {
      window.console.log('success', response.status)
      this.dataList = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    detail(item) {
      this.dataDic = item
      this.isShowModal = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
<style scoped>
.card_ {
  width: 100%;
  margin: 1% 1% 1% 0;
}
.day_ {
  font-size: 0.8rem;
  /* font-weight: 600; */
}
.time_ {
  font-size: 1.4rem;
}
.categoryLabel {
  font-size: 0.8rem;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .categoryLabel {
    font-size: 1.4rem;
  }
}
.titleText {
  font-size: 1.2rem;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .titleText {
    font-size: 1.4rem;
  }
}
</style>
