<template>
  <b-row class="match-height">
    <b-col lg="12">
      <ListApply />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ListApply from '@/components/jobSeeker/apply/ListApply.vue'

export default {
  components: {
    BRow,
    BCol,
    ListApply,
  },
}
</script>

<style lang="scss">
</style>
